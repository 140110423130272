import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

export default function NotFoundPage() {
  useEffect(() => {
    navigate('/404.html')
  }, [])

  return (
    <div>
      <h1>(404) NotFound Page</h1>
    </div>
  )
}
